#documentation {
  h1 {
    color: white;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      color: #71b8ab;
      text-align: center;
      margin-top: 40px;
    }
  }

  .titre-big {
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }
}

#documentation .content{
  margin-top: -450px;
  text-align: center;
  @include media-breakpoint-down(md) {
    margin-top: 3rem !important;
  }
}
#documentation .container{
  margin-top: 450px;

  @include media-breakpoint-down(md) {
    margin-top: 3rem !important;
  }
}