
header #menu-site{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}
header #menu-site ul{
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1800px;
  margin: auto;
  padding: 20px 0;
}
header #menu-site ul a{
  color:  #ffffff;
}

header #menu-site ul a:hover{
  text-decoration: none;
}

.header{
  position: relative;
}
.header img{
  width: 100%;
}
.header .titre_content{
  position: absolute;
  right: 0;
  width: 50%;
  bottom: 20%;
  padding-right : 15%;

  /** En dessous de **/
  @include media-breakpoint-down(lg) {
    position: relative;
    width: 100%;
    bottom: 20px;
    padding-right: 0;
  }
}

