/********************************************
BTN GEN
 */

.btn-gen{
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color:#666666;
  font-size:1.02vw;
  text-decoration: none;
  text-transform: uppercase;
  border:1px solid #666666;
  border-radius:20px;
  padding:2px 15px;
  vertical-align: middle;

  /** En dessous de **/
  @include media-breakpoint-down(sm) {
      font-size:3.125vw;
  }
}
.btn-gen:hover{
  color:#ffffff;
  background: #666666;
  text-decoration: none;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}



/********************************************
BTN AVEC ICONE
 */

.btn-login:before{
  content: "\f2f6";
  /* >> Name of the FA free font (mandatory)
             - 'Font Awesome 5 Free' for Regular and Solid symbols;
             - 'Font Awesome 5 Brand' for Brands symbols. */
  font-family: 'Font Awesome 5 Free';
  /* >> Weight of the font (mandatory)
             - 400 for Regular and Brands symbols;
             - 900 for Solid symbols. */
  font-weight: 900;
  margin:0 5px 0 0;
}


.btn-new:before{
  content: "\f067";
  /* >> Name of the FA free font (mandatory)
             - 'Font Awesome 5 Free' for Regular and Solid symbols;
             - 'Font Awesome 5 Brand' for Brands symbols. */
  font-family: 'Font Awesome 5 Free';
  /* >> Weight of the font (mandatory)
             - 400 for Regular and Brands symbols;
             - 900 for Solid symbols. */
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-filters:before{
  content: "\f0b0";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-save:before{
  content: "\f0c7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-see:before{
  content: "\f06e";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-add:before{
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-edit:before{
  content: "\f044";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-list:before{
  content: "\f0ca";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-csv:before{
  content: "\f1c3";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-upload:before{
  content: "\f093";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-download:before{
  content: "\f019";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}
.btn-trash:before{
  content: "\f1f8";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-logout:before{
  content:"\f2f5";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-cogs:before{
  content:"\f085";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-check:before{
  content:"\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin:0 5px 0 0;
}

.btn-dpm{
  color: #fff;
  text-transform: uppercase;
  padding: 17px 34px;
  border: solid 1px #71b8ab;
  border-radius: 50px;
  background: rgba(0,0,0,.5);
}
.btn-dpm:hover{
  color: #71b8ab;
}

.btn-dpm-light{
  color: #1e1e1e;
  text-transform: uppercase;
  padding: 17px 34px;
  border: solid 1px #71b8ab;
  border-radius: 50px;
}
.btn-dpm-light:hover{
  color: #fff;
  background: rgba(0,0,0,.5);
}

.btn-dpm-white{
  color: #1e1e1e;
  text-transform: uppercase;
  padding: 17px 34px;
  border: solid 1px #71b8ab;
  border-radius: 50px;
  background: rgba(255,255,255,.5);
}
.btn-dpm-white:hover{
  color: #71b8ab;
}