#actualities {

  h1 {
    color: white;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      color: #71b8ab;
      text-align: center;
      margin-top: 40px;
    }
  }

  .titre-big {
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }

  .first_part {
    margin-top: -400px;
    text-align: left;
    @include media-breakpoint-down(md) {
      margin-top: 3rem !important;
      text-align: center;
    }
  }
  .actuality_filter,
  .actuality.reset{
    cursor: pointer;
  }
  .background-orange{
    background: #71b8ab;
    color: #ffffff;
  }
}

.actuality_vignette_content {
  height: 100%;
  position: relative;
  box-shadow: 20px 21px 59px -21px rgba(0, 0, 0, 0.75);

  a {
    color: #1e1e1e;
  }

  a:hover {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 20px;
    min-height: 40px;
    color: #71b8ab;
  }

  .image_article {
    height: 200px;
    overflow: hidden;
    background-position: top !important;
    background-size: cover !important;
  }

  .content {
    position: relative;
    background: #fff;
    padding: 40px 25px 20px;
  }

  .content-footer {
    border-top: 1px solid #c9c9c9;
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between
  }
}
#actualities_filter{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;

}
#actualities_filter>span{
  margin: 10px 0;
}