@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
.testimonies {
  .quote{
    text-align: center;
    font-size: 1.3rem;
    margin: auto;
    padding: 15px;
    border: 2px solid #71b8ab;
    max-width: 70%;
    position: relative;
    margin-top: 50px;
    blockquote{
      /*   color: white; */
      font-style: italic;
      position: relative;
      z-index: 20;
    }
    .left{
      position: absolute;
      top: -50px;
      left: -20px;
      width: 100px;
      text-align: left;
      z-index: 10;
      font-size: 5rem;
      color: #71b8ab;
      background-color: #ffffff;
      line-height: 150px;
    }
    .right{
      position: absolute;
      bottom: -50px;
      right: -20px;
      width: 100px;
      text-align: right;
      z-index: 10;
      font-size: 5rem;
      color: #71b8ab;
      background-color: #ffffff;
      line-height: 150px;
    }
    small{
      font-size: 1rem;
      color: #336699;
      position: relative;
      z-index: 20;
      &:before{
        content: "\2014 \0020";
        width: 5px;
      }
    }
  }

}