
#home .accroche{
  margin: 50px auto 82px;
  @include media-breakpoint-down(lg) {
    margin: 50px auto 40px;
  }
}
#home .accroche h2{
  font-size: 2.5rem;
  margin-bottom: 40px;
}
#home .steps .row{
  padding: 30px 0;
}
#home .steps img{
  //height: 100%;

  /** En dessous de **/
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}
#home .steps h3{
  font-size: 2.2rem;
  font-weight: bold;
}
#home{

  .titre_content h1{
    color: white;
    @include media-breakpoint-down(lg) {
      color: black;
      text-align: center;
      margin-top: 40px;
    }
  }
  .titre_content .titre-big{
    font-size: 3rem;
    @include media-breakpoint-down(lg) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }
  .titre_content .titre-small{
    font-size: 2rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
  .titre_content .lien{
    margin-top: 30px;
  }
}