/*********************************************
OVERRIDE DE HCOFFCANVAS
 */
.hc-offcanvas-nav .nav-close a:hover{
  color: #FFFFFF;
}
.hc-nav-trigger{
  right: 20px;
  position: fixed;
}
#menu-site.menu-scroll{
  background: white;
  z-index: 99;
  box-shadow: 0 0 3px rgba(0,0,0,.5);
  .logo-menu img{
    width: 50%;
    margin: auto;
    text-align: center;
    display: block
  }
  a{
    color: #1e1e1e;
  }
}
.hc-offcanvas-nav .nav-container, .hc-offcanvas-nav .nav-wrapper, .hc-offcanvas-nav ul{
  background: #999;
  border: none;
}
.hc-offcanvas-nav a, .hc-offcanvas-nav .nav-item{
  border: none;
}
.hc-offcanvas-nav li.nav-close a, .hc-offcanvas-nav li.nav-back a{
  background: #999;
  border: none;
}
.hc-offcanvas-nav:not(.touch-device) a:hover{
  background: #71b8ab;

}
.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover{
  background: #71b8ab;
}


.hc-nav-trigger span, .hc-nav-trigger span::before, .hc-nav-trigger span::after {
  background: #71b8ab;
}

.try_it {
    border: solid #71b8ab;
  padding: 5px 10px;
  border-radius: 10px;

  &:hover {
    background: #71b8ab;
    cursor: pointer;
    a{

      color: white!important;
    }
  }

  @include media-breakpoint-down(md) {
    border: none;
    padding-left: 0;
    background: #71b8ab;
    border-radius: 0;
  }
}
#menu-site>ul>li:not(:nth-child(5)){
  width: 12%;
  text-align: center;
}
.connection{
  z-index: 9999;
  max-width: 1800px;
  text-align: right;
  position: relative;
  top: 0;
  margin: auto auto -28px;
  padding-right: 30px;

  @include media-breakpoint-down(md) {
    position: absolute;
    left: 10px;
    right: inherit;
  }
}
.connection a{
  color: #71b8ab
}
