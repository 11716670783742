#product {
  .titre_header{
    color: white;
    margin-bottom: 40px;
    font-weight: 700;
    @include media-breakpoint-down(md) {
      text-align: center;
      margin-top: 40px;
    }

  }

  h1 {
    color: #71b8ab;
    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
    }
    //margin-bottom: 40px;
    //@include media-breakpoint-down(md) {
    //  text-align: center;
    //  margin-top: 40px;
    //  color: #71b8ab;
    //}
  }
  h2{

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      margin-top: 10px;
    }
  }

  .titre-big {
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }

  .first_part {
    margin-top: -1000px;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }
}

#product .vignette {
  padding: 50px;
  background: #ffffff;
  box-shadow: 20px 21px 59px -21px rgba(0, 0, 0, 0.75);

  @include media-breakpoint-down(md) {
    padding: 30px;
  }
}

#product .vignette img {
  margin: auto;
  display: block;
}

#product .vignette h3 {
  text-align: center;
  margin: 10px 0 30px;
  font-weight: 700;
  font-size: 1.5rem;
}

.adherent {
  margin-top: -200px;
  @include media-breakpoint-down(md) {
    margin: 20px 0;
  }
}

.adherent .vignette {
  width: 90%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.alerte .vignette {
  position: relative;
}

.alerte .vignette:after {
  content: '';
  display: block;
  width: 190px;
  height: 200px;
  background: #70b7aa;
  position: absolute;
  right: 0;
  top: 70%;
  z-index: -1
}

.presence .vignette {
  width: 60%;
  margin-left: 20%;
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 0;
  }
}

.snake {
  position: relative;
  margin-top: -50px;
  z-index: -1;
  margin-bottom: -300px;
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 20px 0;
  }
}

.snake img {
  display: block;
  margin: auto
}

.export .vignette {
  width: 65%;
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 0;
  }
}

.mailing, .export {
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 20px 0;
  }

}

.compta .vignette {
  margin-top: -250px;
  width: 110%;
  margin-left: -20%;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    width: 100%;
    margin-left: 0;
  }
}

.boutique .vignette, .logistique .vignette {
  margin-top: 50px;
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
}

.boutique .vignette:after {
  content: '';
  display: block;
  width: 3000px;
  height: 300px;
  background: #70b7aa;
  position: absolute;
  right: 80%;
  bottom: 70%;
  z-index: -1;
}

.logistique .vignette {
  width: 70%;
  margin-left: 18%;
  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0;
  }
}

.paon {
  margin-block: -100px
}

.paon img {
  width: 100%;
}
.fifth_part .titre_tarif{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10%;
  margin-top: -230px
}
.fifth_part .titre_tarif h4 a{
  font-weight: bold;
}
.green{
  color: green;
}
#tarifs table tr td,
#tarifs table tr th{
  padding: 10px;
}
#tarifs table tr td:not(:first-child),
#tarifs table tr th:not(:first-child){
  text-align: center;
}
#tarifs table thead th{
  color: #70b7aa;
}
