footer img{
  width: 100%;
}
footer .left,
footer .right{
  padding: 0;
  margin: 0;
  position: relative;
}
footer .content{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat!important;;
  background-size: cover!important;
  background-position: top center!important;
}
footer .footer_content{
  top: 0;
  height: 100%;
  padding: 50px 30px;

}
footer h4{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5%;
}
footer .right{
  color: #ffffff;
}
footer p{
  line-height: 1.5;
}
#sous-footer{
  background: #1e1e1e;
  padding: 50px 0;
}
#sous-footer .logo-white{
  margin: auto;
}
#sous-footer .copyrights,
#sous-footer .copyrights a{
  color: #fff;
}
footer p{
  height: 12vh;

  @include media-breakpoint-down(md) {
    height: 20vh;
  }
}
footer{
  margin-top: 7vh;
}
