#contact {
  .header .titre_content {
    position: absolute;
    left: 0;
    width: 45%;
    top: 32%;
    padding-left: 0;
    margin-left: 4%;
    height: 1500px;
    bottom: 0;
    padding-right: 5%;
  }

  h1{
    color: white;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      color: #71b8ab;
      text-align: center;
      margin-top: 40px;
    }
  }


  .sous-titre {
    margin-top: 350px;
    color: #71b8ab;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }
  }
  .titre-big{
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }
  form label {
    font-size: 2rem;
    text-transform: uppercase;
    color: #71b8ab;
    margin-top: 20px;
  }

  form input {
    border: solid 1px #979797;
    background: rgba(151, 151, 151, .5);
    height: 40px;
    color: #1e1e1e
  }

  form textarea {
    border: solid 1px #979797;
    background: rgba(151, 151, 151, .5);
    height: 200px;
    color: #1e1e1e
  }
}
#contact .content.contact-demo{
  margin-top: -400px;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }
  p{
    color: white;
    @include media-breakpoint-down(md) {
      color: black;
    }
  }
}
#contact .content{
  margin-top: -500px;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }
}
#contact .first_part{
  padding: 0 5%
}
.second_part .row{
  height: 100%;
  align-content: flex-end;
}
.second_part label{
  font-size: initial!important;
  color: #1e1e1e!important;
  text-transform: none!important;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
    font-size: 1rem;
  }
}
.second_part input[type=checkbox] {
  transform: scale(1.5);
  float: left;
  margin-right: 20px;
}