/*********************************************
    IMPORT BOOTSRAP
 */
@import "~bootstrap/scss/bootstrap";
@import "~jquery-ui/themes/base/all.css";
@import "../js/hc-offcanvas/hc-offcanvas-nav.css";

/**********************************************************
IMPORT DES FICHIERS SCSS
 */

@import "variables";
@import "functions";
@import "reset";
@import "footer";
@import "components/utils";

@import "components/btn";
@import "components/login";
@import "components/title";
@import "components/alert";
@import "components/breadcrumb";


@import "forms/checkbox";
@import "forms/radio";
@import "forms/password";
@import "forms/datepicker";
@import "forms/error";
@import "forms/form";

@import "pages/home";
@import "pages/contact";
@import "pages/tarif";
@import "pages/produit";
@import "pages/documentation";
@import "pages/actuality";
@import "pages/testimonies";

/**********************************************************
OVERRIDES
 */

/**********************************************************
FONTS
 */
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
/**********************************************************
GENERAL
 */
body {
  font-family: 'Raleway', sans-serif;
  color:$txt_color_dark;

  /** A partir de **/
  @include media-breakpoint-up(xs) {

  }

  /** En dessous de **/
  @include media-breakpoint-down(xs) {

  }
  a{
    font-family: 'Raleway', sans-serif;
  }
}

/**********************************************************
HEADER
 */
@import "header";

/**********************************************************
MENU
 */
@import "menu";

/**********************************************************
FORMULAIRE
 */

/*********************************************
    ETC...
 */
.no-header{
  /*   border: solid; */
  max-width: 1800px;
  margin: auto;
}
.trait_bottom{
  position: relative;
}
.trait_bottom:after{
  content: '';
  display: block;
  width: 10%;
  height: 2px;
  background: #71b8ab;
  position: absolute;
  left: 45%;
  bottom: -10px;
  @include media-breakpoint-down(lg) {
    width: 40%;
    left: 30%;
  }
}

.blue{

  color: #71b8ab;
}

#confidencialite,
#mention_legales{
  margin-top: 200px;
}