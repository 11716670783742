#tarifs {
  h1 {
    color: white;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      color: #71b8ab;
      text-align: center;
      margin-top: 40px;
    }
  }

  .titre-big {
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      color: #71b8ab;
    }
  }

  .first_part {
    margin-top: 60px;

    @include media-breakpoint-down(md) {
      margin-top: 3rem !important;
    }
    .vinette_tarif {
      width: 70%;
      margin-left: 20%;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .second_part {
    margin-top: -500px;

    @include media-breakpoint-down(md) {
      margin-top: 3rem !important;
    }
    .vinette_tarif {
      width: 80%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .vinette_tarif {
    padding: 50px;
    background: #ffffff;
    box-shadow: 20px 21px 59px -21px rgba(0, 0, 0, 0.75);

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    h3 {
      text-transform: uppercase;
      text-align: center;
      font-size: 2.5rem;
      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }

    .price {
      border: solid;
      text-align: center;
      color: white;
      background: #71b8ab;
      padding: 10px;
      font-size: 2rem;
      font-weight: bold;
      margin: 20px 0;

      .small {
        font-size: 1rem;
        font-weight: bold
      }
    }

    ul {
      /*   list-style: none; */
      padding: 0;

      li {
        margin: 10px 0;
        height: 50px;
        display: flex;
        align-items: center;

        p {
          padding: 0 0 0 20px;
          margin: 0 0 0 10px;
          line-height: 1.2;
          position: relative
        }

        p:before {
          content: '';
          width: 5px;
          height: 5px;
          background: #1e1e1e;
          display: block;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
  }

  .equestre {
    img {
      width: 70%;
      display: block;
      margin: -10vh auto auto;
      z-index: -1;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 90%;
        margin: -2vh auto -10vh;
      }
    }
  }

  .nac {
    img {
      display: block;
      margin: auto;
      margin-top: -25vh;
      position: relative;
      z-index: -1;

      @include media-breakpoint-down(md) {
        width: 60%;
        margin: -15vh auto 0;
      }
    }
  }
}